<template>
  <div>
    <div
      class="form-group d-flex justify-content-center col-lg-12 px-0"
      v-if="userLoading"
    >
      <div class="loader"></div>
    </div>
    <div v-else>
      <div class="my-4 col-lg-6 px-0">
        <h3 class="ana_h3 ana_text-dark">Account Update</h3>
        <p class="ana_paragraph ana_text-grey">Update your account details</p>
      </div>
      <AvatarChange />
      <form class="form" @submit.prevent="updateProfile">
        <div class="form-group col-lg-12 px-0">
          <label for="firstName" class="ana-label">First Name</label>
          <input
            type="text"
            name="firstName"
            class="ana-input w-100"
            v-model.trim="$v.userProfile.firstName.$model"
            required
          />
        </div>
        <div class="form-group col-lg-12 px-0">
          <label for="lastName" class="ana-label">Last Name</label>
          <input
            type="text"
            name="lastName"
            class="ana-input w-100"
            v-model.trim="$v.userProfile.lastName.$model"
            required
          />
        </div>
        <div class="form-group col-lg-12 px-0">
          <label for="lastName" class="ana-label"
            >Email <small>(Heads up, you cannot change this)</small></label
          >
          <div class="ana-input w-100 text-muted">
            {{ user.email }}
          </div>
        </div>
        <div class="form-group col-lg-12 px-0" v-if="isLoadingProfile">
          <div class="linear-loader"></div>
        </div>
        <div class="form-group col-lg-12 px-0">
          <button
            type="submit"
            class="ana-btn ana-btn__fill mt-2 mb-5 ana-btn-dash"
            :disabled="isLoadingProfile || $v.$invalid"
          >
            Save Changes
          </button>
        </div>
      </form>
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";
import axios from "axios";
import AvatarChange from "@/components/dash/AvatarChange";
import { required, minLength, between, email } from "vuelidate/lib/validators";
export default {
  name: "AccountUpdate",
  components: {
    AvatarChange,
  },
  data() {
    return {
      isLoadingProfile: false,
      userLoading: true,
      userProfile: {
        firstName: "",
        lastName: "",
        current_password: "",
        new_password: "",
      },
    };
  },
  validations: {
    userProfile: {
      firstName: {
        required,
      },
      lastName: {
        required,
      },
    },
  },
  async mounted() {
    const res = await axios.get("me");
    try {
      if (res) {
        this.userProfile.firstName = res.data.user.firstName;
        this.userProfile.lastName = res.data.user.lastName;
        this.userLoading = false;
      }
    } catch (err) {
      this.userLoading = false;
      this.$toast(`${err.response.data.message}`, {
        timeout: 2000,
        type: "error",
      });
    }
  },
  computed: {
    ...mapState(["isUserLoggedIn", "user"]),
  },
  methods: {
    updateProfile() {
      this.isLoadingProfile = true;
      let profile = {
        userID: this.user._id,
        firstName: this.userProfile.firstName,
        lastName: this.userProfile.lastName,
      };
      axios
        .put(`profile/update/${this.user._id}`, profile)
        .then((res) => {
          this.$toast.success(`${res.data.message}`);
          this.$store.dispatch("setUser", res.data.updatedProfile);
          this.isLoadingProfile = false;
        })
        .catch((err) => {
          this.$toast.error(`${err.response.data.message}`);
          this.isLoadingProfile = false;
        });
    },
  },
};
</script>

<style></style>
