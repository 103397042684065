<template>
  <section>
    <div class="app-wrapper">
      <div class="container py-5">
        <div class="col-lg-10 mx-auto p-0 p-0">
          <div class="py-5 d-flex align-items-center justify-content-between">
            <h3 class="ana_h3 mb-0">Settings</h3>
            <div>
              <span class="cancel" @click="$router.go(-1)">
                <i class="ti-close"></i>
              </span>
            </div>
          </div>
          <div class="d-flex align-items-center ana-tabs">
            <a
              v-for="(tab, i) in tabs"
              :key="i"
              href="javascript:void(0)"
              class="ana-tabs__item text-capitalize"
              :class="[activeTab == tab && 'ana-tabs__item--active']"
              @click="setTab(tab)"
              >{{ tab }}</a
            >
          </div>
          <div class="dropdown-divider my-3"></div>
          <AccountUpdate v-if="activeTab == 'profile'" />
          <PasswordUpdate v-if="activeTab == 'password'" />
          <AppearanceUpdate v-if="activeTab == 'appearance'" />
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import PasswordUpdate from "@/components/dash/Account/PasswordUpdate";
import AccountUpdate from "@/components/dash/Account/AccountUpdate";
import AppearanceUpdate from "@/components/dash/Account/AppearanceUpdate";
export default {
  metaInfo: {
    title: "Your Account - Ana app",
  },
  components: {
    PasswordUpdate,
    AccountUpdate,
    AppearanceUpdate,
  },
  data() {
    return {
      tabs: ["profile", "password", "appearance"],
      activeTab: "profile",
    };
  },
  methods: {
    setTab(tab) {
      this.activeTab = tab;
    },
  },
};
</script>
