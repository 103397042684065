<template>
  <div>
    <form class="form" @submit.prevent="updatePassword">
      <div class="my-4 col-lg-6 px-0">
        <h3 class="ana_h3 ana_text-dark">Change Password</h3>
        <p class="ana_paragraph ana_text-grey">Update your account password</p>
      </div>
      <div class="form-group col-lg-12 px-0">
        <label for="password" class="ana-label">Current Password</label>
        <div class="input-group mb-3 ana-input__group">
          <div class="input-group-append">
            <span class="input-group-text" id="basic-addon2"
              ><i class="ti-lock"></i
            ></span>
          </div>
          <input
            :type="type"
            name="password"
            class="form-control ana-input"
            v-model.trim="$v.userProfile.current_password.$model"
            placeholder="*******"
            required
          />
        </div>
        <InlineAlert
          alertMessage="Password should be at least 6 characters"
          alertType="error"
          v-if="
            userProfile.current_password &&
            $v.userProfile.current_password.$invalid
          "
        />
      </div>
      <div class="form-group col-lg-12 px-0">
        <label for="password" class="ana-label">New Password</label>
        <div class="input-group mb-3 ana-input__group">
          <div class="input-group-append">
            <span class="input-group-text" id="basic-addon2"
              ><i class="ti-lock"></i
            ></span>
          </div>
          <input
            :type="type"
            name="password"
            class="form-control ana-input"
            v-model.trim="$v.userProfile.new_password.$model"
            placeholder="*******"
            required
          />
        </div>
        <InlineAlert
          alertMessage="Password should be at least 6 characters"
          alertType="error"
          v-if="
            userProfile.new_password && $v.userProfile.new_password.$invalid
          "
        />
      </div>
      <div class="form-group col-lg-12 px-0">
        <label for="password" class="ana-label">Repeat Password</label>
        <div class="input-group mb-3 ana-input__group">
          <div class="input-group-append">
            <span class="input-group-text" id="basic-addon2"
              ><i class="ti-lock"></i
            ></span>
          </div>
          <input
            :type="type"
            name="password"
            class="form-control ana-input"
            v-model.trim="$v.confirm_password.$model"
            placeholder="*******"
            required
          />
        </div>
        <InlineAlert
          alertMessage="Passwords do not match"
          alertType="error"
          v-if="
            confirm_password && confirm_password !== userProfile.new_password
          "
        />
      </div>
      <div class="form-group col-lg-12 px-0">
        <div class="d-flex align-items-center">
          <small class="mr-2 mb-2"> Show passwords</small>
          <label class="switch"
            ><input
              type="checkbox"
              @change="showPassword"
              :checked="type == 'text'"
            />
            <div></div>
          </label>
        </div>
      </div>
      <div class="form-group col-lg-12 px-0" v-if="isLoadingPassword">
        <div class="linear-loader"></div>
      </div>
      <div class="form-group col-lg-12 px-0">
        <button
          type="submit"
          class="ana-btn ana-btn__fill ana-btn-dash"
          :disabled="
            isLoadingPassword ||
            $v.$invalid ||
            confirm_password !== userProfile.new_password
          "
        >
          Update Password
        </button>
      </div>
    </form>
  </div>
</template>

<script>
import axios from "axios";
import { required, minLength, between, email } from "vuelidate/lib/validators";
import { mapState } from "vuex";
export default {
  name: "PasswordUpdate",
  data() {
    return {
      confirm_password: "",
      type: "password",
      isLoadingPassword: false,
      userProfile: {
        current_password: "",
        new_password: "",
      },
    };
  },
  validations: {
    confirm_password: {
      required,
    },
    userProfile: {
      current_password: {
        required,
        minLength: minLength(6),
      },
      new_password: {
        required,
        minLength: minLength(6),
      },
    },
  },
  computed: {
    ...mapState(["isUserLoggedIn", "user"]),
  },
  methods: {
    updatePassword() {
      this.isLoadingPassword = true;
      let passwords = {
        currentPassword: this.userProfile.current_password,
        newPassword: this.userProfile.new_password,
      };
      if (this.userProfile.new_password !== this.confirm_password) {
        this.$toast.error(`Passwords do not match`);
        this.isLoadingPassword = false;
      } else {
        axios
          .put(`password/update/${this.user._id}`, passwords)
          .then((res) => {
            this.$toast(`${res.data.message}`, {
              timeout: 2000,
              type: "success",
            });
            this.isLoadingPassword = false;
            this.resetFields();
          })
          .catch((err) => {
            this.$toast(`${err.response.data.message}`, {
              timeout: 2000,
              type: "error",
            });
            this.isLoadingPassword = false;
          });
      }
    },
    showPassword() {
      if (this.type === "password") {
        this.type = "text";
      } else {
        this.type = "password";
      }
    },
    resetFields() {
      this.userProfile.new_password = "";
      this.userProfile.current_password = "";
      this.confirm_password = "";
      this.type = "password";
    },
  },
};
</script>

<style></style>
