var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('form',{staticClass:"form",on:{"submit":function($event){$event.preventDefault();return _vm.updatePassword.apply(null, arguments)}}},[_vm._m(0),_c('div',{staticClass:"form-group col-lg-12 px-0"},[_c('label',{staticClass:"ana-label",attrs:{"for":"password"}},[_vm._v("Current Password")]),_c('div',{staticClass:"input-group mb-3 ana-input__group"},[_vm._m(1),_c('input',{directives:[{name:"model",rawName:"v-model.trim",value:(_vm.$v.userProfile.current_password.$model),expression:"$v.userProfile.current_password.$model",modifiers:{"trim":true}}],staticClass:"form-control ana-input",attrs:{"type":_vm.type,"name":"password","placeholder":"*******","required":""},domProps:{"value":(_vm.$v.userProfile.current_password.$model)},on:{"input":function($event){if($event.target.composing)return;_vm.$set(_vm.$v.userProfile.current_password, "$model", $event.target.value.trim())},"blur":function($event){return _vm.$forceUpdate()}}})]),(
          _vm.userProfile.current_password &&
          _vm.$v.userProfile.current_password.$invalid
        )?_c('InlineAlert',{attrs:{"alertMessage":"Password should be at least 6 characters","alertType":"error"}}):_vm._e()],1),_c('div',{staticClass:"form-group col-lg-12 px-0"},[_c('label',{staticClass:"ana-label",attrs:{"for":"password"}},[_vm._v("New Password")]),_c('div',{staticClass:"input-group mb-3 ana-input__group"},[_vm._m(2),_c('input',{directives:[{name:"model",rawName:"v-model.trim",value:(_vm.$v.userProfile.new_password.$model),expression:"$v.userProfile.new_password.$model",modifiers:{"trim":true}}],staticClass:"form-control ana-input",attrs:{"type":_vm.type,"name":"password","placeholder":"*******","required":""},domProps:{"value":(_vm.$v.userProfile.new_password.$model)},on:{"input":function($event){if($event.target.composing)return;_vm.$set(_vm.$v.userProfile.new_password, "$model", $event.target.value.trim())},"blur":function($event){return _vm.$forceUpdate()}}})]),(
          _vm.userProfile.new_password && _vm.$v.userProfile.new_password.$invalid
        )?_c('InlineAlert',{attrs:{"alertMessage":"Password should be at least 6 characters","alertType":"error"}}):_vm._e()],1),_c('div',{staticClass:"form-group col-lg-12 px-0"},[_c('label',{staticClass:"ana-label",attrs:{"for":"password"}},[_vm._v("Repeat Password")]),_c('div',{staticClass:"input-group mb-3 ana-input__group"},[_vm._m(3),_c('input',{directives:[{name:"model",rawName:"v-model.trim",value:(_vm.$v.confirm_password.$model),expression:"$v.confirm_password.$model",modifiers:{"trim":true}}],staticClass:"form-control ana-input",attrs:{"type":_vm.type,"name":"password","placeholder":"*******","required":""},domProps:{"value":(_vm.$v.confirm_password.$model)},on:{"input":function($event){if($event.target.composing)return;_vm.$set(_vm.$v.confirm_password, "$model", $event.target.value.trim())},"blur":function($event){return _vm.$forceUpdate()}}})]),(
          _vm.confirm_password && _vm.confirm_password !== _vm.userProfile.new_password
        )?_c('InlineAlert',{attrs:{"alertMessage":"Passwords do not match","alertType":"error"}}):_vm._e()],1),_c('div',{staticClass:"form-group col-lg-12 px-0"},[_c('div',{staticClass:"d-flex align-items-center"},[_c('small',{staticClass:"mr-2 mb-2"},[_vm._v(" Show passwords")]),_c('label',{staticClass:"switch"},[_c('input',{attrs:{"type":"checkbox"},domProps:{"checked":_vm.type == 'text'},on:{"change":_vm.showPassword}}),_c('div')])])]),(_vm.isLoadingPassword)?_c('div',{staticClass:"form-group col-lg-12 px-0"},[_c('div',{staticClass:"linear-loader"})]):_vm._e(),_c('div',{staticClass:"form-group col-lg-12 px-0"},[_c('button',{staticClass:"ana-btn ana-btn__fill ana-btn-dash",attrs:{"type":"submit","disabled":_vm.isLoadingPassword ||
          _vm.$v.$invalid ||
          _vm.confirm_password !== _vm.userProfile.new_password}},[_vm._v(" Update Password ")])])])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"my-4 col-lg-6 px-0"},[_c('h3',{staticClass:"ana_h3 ana_text-dark"},[_vm._v("Change Password")]),_c('p',{staticClass:"ana_paragraph ana_text-grey"},[_vm._v("Update your account password")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"input-group-append"},[_c('span',{staticClass:"input-group-text",attrs:{"id":"basic-addon2"}},[_c('i',{staticClass:"ti-lock"})])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"input-group-append"},[_c('span',{staticClass:"input-group-text",attrs:{"id":"basic-addon2"}},[_c('i',{staticClass:"ti-lock"})])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"input-group-append"},[_c('span',{staticClass:"input-group-text",attrs:{"id":"basic-addon2"}},[_c('i',{staticClass:"ti-lock"})])])
}]

export { render, staticRenderFns }