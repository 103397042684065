<template>
  <div>
    <SetPassword v-if="usesGoogleAuth" />
    <ChangePassword v-else />
  </div>
</template>

<script>
import { mapState } from "vuex";
import SetPassword from "./SetPassword";
import ChangePassword from "./ChangePassword";
export default {
  name: "PasswordUpdate",
  components: {
    SetPassword,
    ChangePassword,
  },
  computed: {
    ...mapState(["isUserLoggedIn", "user"]),
    usesGoogleAuth() {
      return this.user.authType && this.user.authType == "google"
        ? true
        : false;
    },
  },
  methods: {
    async setPassword() {
      this.isLoadingPassword = true;
      if (this.userProfile.new_password !== this.confirm_password) {
        this.$toast.error(`Passwords do not match`);
        return;
      }
      try {
        const { data } = await axios.put(`password/set/${this.user._id}`, {
          password: this.userProfile.new_password,
        });
        this.$store.dispatch("setUser", data.user);
        this.$toast.success(`You have added a password to your account`);
        this.resetFields();
      } catch (error) {
        this.$toast.error(`An error occurred setting password`);
      } finally {
        this.isLoadingPassword = false;
      }
    },
    updatePassword() {
      this.isLoadingPassword = true;
      let passwords = {
        currentPassword: this.userProfile.current_password,
        newPassword: this.userProfile.new_password,
      };
      if (this.userProfile.new_password !== this.confirm_password) {
        this.$toast.error(`Passwords do not match`);
        this.isLoadingPassword = false;
      } else {
        axios
          .put(`password/update/${this.user._id}`, passwords)
          .then((res) => {
            this.$toast(`${res.data.message}`, {
              timeout: 2000,
              type: "success",
            });
            this.isLoadingPassword = false;
            this.resetFields();
          })
          .catch((err) => {
            this.$toast(`${err.response.data.message}`, {
              timeout: 2000,
              type: "error",
            });
            this.isLoadingPassword = false;
          });
      }
    },
    showPassword() {
      if (this.type === "password") {
        this.type = "text";
      } else {
        this.type = "password";
      }
    },
    resetFields() {
      this.userProfile.new_password = "";
      this.userProfile.current_password = "";
      this.confirm_password = "";
      this.type = "password";
    },
  },
};
</script>

<style></style>
