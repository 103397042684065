<template>
  <div class="d-flex align-items-center">
    <div class="upload-area mr-2">
      <div class="avatar-loading" v-if="uploadProgress > 0"></div>
      <input
        type="file"
        ref="fileInput"
        @change="handleFileChange"
        accept="image/jpeg, image/png"
        v-if="uploadProgress <= 0"
      />
      <div v-if="initialImage">
        <img :src="initialImage" />
        <div class="upload-icon">
          <span class="bi bi-image"></span>
        </div>
      </div>
      <img v-else :src="selectedFileUrl" alt="Selected Image" />
    </div>
    <div>
      <label for="firstName" class="ana-label">Profile Image</label>
      <p class="small ana_text-dark mb-0">
        You can use .jpg, .png and file must be less than 2mb
      </p>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import { mapState } from "vuex";
export default {
  name: "AvatarChange",
  data() {
    return {
      selectedFile: null,
      selectedFileUrl: null,
      uploadProgress: 0,
    };
  },
  computed: {
    ...mapState(["isUserLoggedIn", "user"]),
    initialImage() {
      return this.user.profileImage
        ? this.user.profileImage
        : "https://api.dicebear.com/6.x/shapes/svg?seed=Casper";
    },
  },
  methods: {
    async handleFileChange(event) {
      const file = event.target.files[0];
      if (file) {
        if (file.size > 2097152) {
          // Max size: 2MB
          console.error("File size exceeds the limit");
          this.$toast.error(`File must be less than 2mb`);
          this.selectedFile = null;
          this.selectedFileUrl = null;
          return;
        }
        this.selectedFile = file;
        this.selectedFileUrl = URL.createObjectURL(file);
        if (!this.selectedFile) {
          console.error("No file selected");
          return;
        }
        const formData = new FormData();
        formData.append("image", this.selectedFile);
        try {
          const response = await axios.post(
            "/profile/updateProfileImage",
            formData,
            {
              headers: {
                "Content-Type": "multipart/form-data",
              },
              onUploadProgress: (progressEvent) => {
                this.uploadProgress = Math.round(
                  (progressEvent.loaded / progressEvent.total) * 100
                );
              },
            }
          );

          console.log("File uploaded:", response.data);
          await this.$store.dispatch("setUser", response.data.user);

          this.selectedFile = null;
          this.selectedFileUrl = null;
          this.uploadProgress = 0;
          this.$toast.success(`Profile Pic Updated`);
        } catch (error) {
          console.error("Upload error:", error);
          this.selectedFile = null;
          this.selectedFileUrl = this.initialImage;
          this.uploadProgress = 0;
          this.$toast.error(`${err.response.data.message}`);
        }
      }
    },
  },
};
</script>
<style>
.upload-area {
  height: 80px;
  width: 80px;
  border-radius: 50%;
  position: relative;
  margin: 2rem 0;
  cursor: pointer;
}
.upload-area img {
  display: block;
  height: 80px;
  width: 80px;
  border-radius: 50%;
  object-fit: cover;
}
.upload-area input {
  position: absolute;
  height: 100%;
  width: 100%;
  opacity: 0;
  z-index: 3;
}
.upload-area .upload-icon {
  position: absolute;
  bottom: 0;
  right: 0;
  height: 24px;
  width: 24px;
  font-size: 12px;
  background: var(--anaGrey2);
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 5px;
  z-index: 1;
}
.avatar-loading {
  position: absolute;
  top: 0;
  left: 0;
  height: 80px;
  width: 80px;
  border-radius: 50%;
  border: 3px dotted transparent;
  border-top: 3px dotted var(--secondaryColor);
  animation: spin 2s linear infinite;
}
@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
</style>
