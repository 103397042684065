<template>
  <div>
    <div>
      <div class="my-4 col-lg-6 px-0">
        <h3 class="ana_h3 ana_text-dark">Set Password</h3>
        <p class="ana_paragraph ana_text-grey">
          You created your account using Google Auth. You will need to set a
          password.
        </p>
      </div>
      <form class="form" @submit.prevent="setPassword">
        <div class="form-group col-lg-12 px-0">
          <label for="password" class="ana-label">Choose Strong Password</label>
          <div class="input-group mb-3 ana-input__group">
            <div class="input-group-append">
              <span class="input-group-text" id="basic-addon2"
                ><i class="ti-lock"></i
              ></span>
            </div>
            <input
              :type="type"
              name="password"
              class="form-control ana-input"
              v-model.trim="$v.userProfile.new_password.$model"
              placeholder="Min. 6 Characters"
              required
            />
          </div>
          <InlineAlert
            alertMessage="Password should be at least 6 characters"
            alertType="error"
            v-if="
              userProfile.new_password && $v.userProfile.new_password.$invalid
            "
          />
        </div>
        <div class="form-group col-lg-12 px-0">
          <label for="password" class="ana-label">Repeat Password</label>
          <div class="input-group mb-3 ana-input__group">
            <div class="input-group-append">
              <span class="input-group-text" id="basic-addon2"
                ><i class="ti-lock"></i
              ></span>
            </div>
            <input
              :type="type"
              name="password"
              class="form-control ana-input"
              v-model.trim="$v.confirm_password.$model"
              placeholder="*******"
              required
            />
          </div>
          <InlineAlert
            alertMessage="Passwords do not match"
            alertType="error"
            v-if="
              confirm_password && confirm_password !== userProfile.new_password
            "
          />
        </div>
        <div class="form-group col-lg-12 px-0">
          <div class="d-flex align-items-center">
            <small class="mr-2 mb-2"> Show passwords</small>
            <label class="switch"
              ><input
                type="checkbox"
                @change="showPassword"
                :checked="type == 'text'"
              />
              <div></div>
            </label>
          </div>
        </div>
        <div class="form-group col-lg-12 px-0" v-if="isLoadingPassword">
          <div class="linear-loader"></div>
        </div>
        <div class="form-group col-lg-12 px-0">
          <button
            type="submit"
            class="ana-btn ana-btn__fill ana-btn-dash"
            :disabled="
              isLoadingPassword ||
              $v.$invalid ||
              confirm_password !== userProfile.new_password
            "
          >
            Set Password
          </button>
        </div>
      </form>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import { mapState } from "vuex";
import { required, minLength, between, email } from "vuelidate/lib/validators";
export default {
  name: "PasswordUpdate",
  data() {
    return {
      confirm_password: "",
      type: "password",
      isLoadingPassword: false,
      userProfile: {
        new_password: "",
      },
    };
  },
  validations: {
    confirm_password: {
      required,
    },
    userProfile: {
      new_password: {
        required,
        minLength: minLength(6),
      },
    },
  },
  computed: {
    ...mapState(["isUserLoggedIn", "user"]),
  },
  methods: {
    async setPassword() {
      this.isLoadingPassword = true;
      if (this.userProfile.new_password !== this.confirm_password) {
        this.$toast.error(`Passwords do not match`);
        return;
      }
      try {
        const { data } = await axios.put(`password/set/${this.user._id}`, {
          password: this.userProfile.new_password,
        });
        this.$store.dispatch("setUser", data.user);
        this.$toast.success(`You have added a password to your account`);
        this.resetFields();
      } catch (error) {
        this.$toast.error(`An error occurred setting password`);
      } finally {
        this.isLoadingPassword = false;
      }
    },
    showPassword() {
      if (this.type === "password") {
        this.type = "text";
      } else {
        this.type = "password";
      }
    },
    resetFields() {
      this.userProfile.new_password = "";
      this.userProfile.current_password = "";
      this.confirm_password = "";
      this.type = "password";
    },
  },
};
</script>

<style></style>
