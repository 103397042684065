var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_vm._m(0),_c('div',{staticClass:"row col-lg-6 px-0"},[_vm._m(1),_c('div',{staticClass:"col-6"},[_c('div',{staticClass:"ana-appeareance",class:{
          'ana-appeareance--active': _vm.AppTheme !== 'dark_theme',
        },on:{"click":function($event){return _vm.toggleTheme('light_theme')}}},[_c('img',{attrs:{"src":require("@/assets/img/theme/light.svg"),"alt":""}}),_c('div',{staticClass:"ana-appeareance__label"},[_vm._v("Light Theme")])])]),_c('div',{staticClass:"col-6"},[_c('div',{staticClass:"ana-appeareance",class:{
          'ana-appeareance--active': _vm.AppTheme === 'dark_theme',
        },on:{"click":function($event){return _vm.toggleTheme('dark_theme')}}},[_c('img',{attrs:{"src":require("@/assets/img/theme/dark.svg"),"alt":""}}),_c('div',{staticClass:"ana-appeareance__label"},[_vm._v("Dark Theme")])])])])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"my-4 col-lg-6 px-0"},[_c('h3',{staticClass:"ana_h3 ana_text-dark"},[_vm._v("Customize Appearance")]),_c('p',{staticClass:"ana_paragraph ana_text-grey"},[_vm._v("Set theme and app appearance")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"col-12 mb-3"},[_c('label',{staticClass:"ana-label",attrs:{"for":"password"}},[_vm._v("App Theme")])])
}]

export { render, staticRenderFns }