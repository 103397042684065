<template>
  <div>
    <div class="my-4 col-lg-6 px-0">
      <h3 class="ana_h3 ana_text-dark">Customize Appearance</h3>
      <p class="ana_paragraph ana_text-grey">Set theme and app appearance</p>
    </div>
    <div class="row col-lg-6 px-0">
      <div class="col-12 mb-3">
        <label for="password" class="ana-label">App Theme</label>
      </div>
      <div class="col-6">
        <div
          class="ana-appeareance"
          @click="toggleTheme('light_theme')"
          :class="{
            'ana-appeareance--active': AppTheme !== 'dark_theme',
          }"
        >
          <img src="@/assets/img/theme/light.svg" alt="" />
          <div class="ana-appeareance__label">Light Theme</div>
        </div>
      </div>
      <div class="col-6">
        <div
          @click="toggleTheme('dark_theme')"
          class="ana-appeareance"
          :class="{
            'ana-appeareance--active': AppTheme === 'dark_theme',
          }"
        >
          <img src="@/assets/img/theme/dark.svg" alt="" />
          <div class="ana-appeareance__label">Dark Theme</div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "AppearanceUpdate",
  data() {
    return {
      darkTheme: false,
    };
  },
  methods: {
    toggleTheme(theme) {
      this.$store.dispatch("theme/toggleTheme", theme);
      this.$emit("themeSwitched");
    },
  },
};
</script>

<style>
.ana-appeareance {
  min-height: 100px;
  width: 100%;
  opacity: 0.5;
  cursor: pointer;
}
.ana-appeareance.ana-appeareance--active {
  opacity: 1;
}
.ana-appeareance img {
  width: 100%;
  border: 2px solid var(--anaGrey2);
  border-radius: var(--anaRadius2);
}
.ana-appeareance.ana-appeareance--active img {
  border-color: var(--secondaryColor);
}
.ana-appeareance .ana-appeareance__label {
  padding: 1rem 0.6rem;
  font-size: 0.875rem;
  font-weight: var(--fontSemibold);
  color: var(--primaryColor);
}
</style>
